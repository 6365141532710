import React, { useState, useEffect } from "react";
import { TailSpin } from "react-loader-spinner";
import { useParams } from "react-router-dom";
import BankDetails from "./BankDetails";

const Code = () => {
  const { id } = useParams();

  //console.log("id: " + id);

  const [codeFromDb, setCodeFromDb] = useState("");
  const [bankFromDb, setBankFromDb] = useState("");
  const [typedCode, setTypedCode] = useState("");
  const [codeOk, setCodeOk] = useState(false);
  const [message, setMessage] = useState("");
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [auth,setAuth] = useState("")


  
  const myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const putHeaders = new Headers();
  putHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    pin: "0000",
  });

  const putOptions = {
    method: "PUT",
    headers: putHeaders,
    body: raw,
    redirect: "follow",
  };

  useEffect(() => {




    // first get the aapi auth token
    //  I had to do it like as the fetch woildn't work when getting the AAD tokens
    // this I call the api and get the auth token this way


    fetch("https://iban.service.kiwijones.com/Codes/GetAuth?client_secret=QW98Q~c439.OA4OOg8BEi64VhyIBXwKXM6K3Jbkr", requestOptions)
    .then((response) => response.text())
    .then((result) => {
      // all good here, now get the details
      let token = JSON.parse(result);

      //console.log(token.result)
     

      let access = JSON.parse(token.result)

      //onsole.log(access.access_token)

      setAuth(access.access_token)

      let bearer = 'Bearer ' + access.access_token
      // now call the api 
      myHeaders.append("Authorization", bearer);

      fetch("https://iban.service.kiwijones.com/Codes/" + id, requestOptions)
      //fetch("https://localhost:5101/Codes/" + id, requestOptions)

      .then((response) => response.text())
      .then((result) => {

        // API RESPONSE
        setSubmitEnabled(true)
        
        //console.log(result);

        let dataCode = JSON.parse(result);

        setCodeFromDb(dataCode.code);
        setBankFromDb(dataCode.bankDetailsId);

        console.log(codeFromDb)

        setLoading(false);
        setSubmitEnabled(false)
      })
      .catch((error) => console.error(error));

      
      setLoading(false);
      setSubmitEnabled(false)
    })
    .catch((error) => console.error(error));


   
    // Do something with the query parameter
  }, []);

  const [loading, setLoading] = useState(true);

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();
    //console.log("Typed:" + typedCode);
    //console.log("FromDB:" + codeFromDb);

    if (
      typedCode.trim().toLocaleLowerCase() ==
      codeFromDb.trim().toLocaleLowerCase()
    ) {
      setMessage("");
    
      //console.log("code ok");
      
      
      // change the one time code 
      // 
      fetch("https://iban.service.kiwijones.com/Codes/id?id=" + id, putOptions)
        .then((response) => response.text())
        .then((result) => {

          

        })
        .catch((error) => console.error(error));

      setLoading(false);
      setCodeOk(true);

    } else {
      setLoading(false);
      setMessage("Invalid code");
    }
  };

  return (
    <>
      {loading ? <img src="../Infinity.svg" /> : ""}

      {codeOk ? (
        <>
          <BankDetails data={bankFromDb} auth={auth} />
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit}>
            <div>
              <i>The code is a one time use only!</i>

              <h3>Enter Code</h3>
              {/* <label htmlFor="username">Username:</label> */}
              <input
                className="custom-input"
                type="password"
                id="code"
                value={typedCode}
                placeholder="code"
                onChange={(e) => setTypedCode(e.target.value)}
                required
              />
            </div>
            {/* {submitEnabled ? 'Enabled' : 'Disabled'} */}
            <br/>
            <button id="submitBtn" className="primary-button p-1" type="submit" disabled={submitEnabled}>
              Submit
            </button>
          </form>
        </>
      )}

      <h3>{message}</h3>
    </>
  );
};

export default Code;
