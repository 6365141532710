import logo from './logo.svg';
import './App.css';
import './Pages/Code';

import React, { useState } from 'react';
import {  BrowserRouter as Router, Route, useLocation, Routes, Link} from 'react-router-dom';

import Code from './Pages/Code';

function App() {

  return (
    <div className="App">
      
      <header className="App-header">
      
      <Router>
       
        <Routes>
        <Route exact path="/" />
          <Route exact path="/code/:id" element={<Code />} />
          {/* <Route path="/forgotpassword" element={<Reset/>} />
          <Route path="/Points" element={<Points/>} /> */}
        </Routes>
       
      </Router>
      </header>

    </div>
  );
}

export default App;
