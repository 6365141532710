import React, { useState, useEffect } from "react";

const BankDetails = (props) => {
  const [sortCode, setSortCode] = useState("");
  const [name, setName] = useState("");
  const [accountCode, setAccountCode] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState("");
  const [bank, setBank] = useState("");
  const [loading, setLoading] = useState(true);
  const [textCopied, setTextCopied] = useState('')
  const [swift, setSwift] = useState('')

  const myHeaders = new Headers();
  myHeaders.append("Accept", "text/plain");


  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setTextCopied(text + " Copied to clipboard");
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  let bearer = "Bearer " + props.auth;
  // now call the api
  myHeaders.append("Authorization", bearer);

  //console.log(bearer)
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  function Tooltip({ message, children }) {
    const [isVisible, setIsVisible] = useState(false);
  
    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);
  
    return (
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {children}
        {isVisible && <span className="tooltip">{message}</span>}
      </div>
    );
  }


  useEffect(() => {
    setLoading(true);
    fetch(
      "https://iban.service.kiwijones.com/Details/" + props.data,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        //console.log(result)

        let dataCode = JSON.parse(result);

        console.log(dataCode.sortCode)

        setSortCode(dataCode.sortCode);
        setName(dataCode.name);
        setAccountCode(dataCode.accountCode);
        setIban(dataCode.iban);
        setBic(dataCode.bic);
        setBank(dataCode.bank);
        setSwift(dataCode.swift);

        setLoading(false);
      })
      .catch((error) => console.error(error));

    // Do something with the query parameter
  }, []);

  return (
    <>
      {loading ? <img src="../Infinity.svg" /> : ""}
      <h3>Banking Details</h3>
      <h4>Copy the details before leaving this page!</h4>
      <i>Click on the details to copy</i>
      <table className="table">
        <tbody>
          <tr>
            <td>Name:</td>
            <td>{name}</td>
          </tr>
          <tr>
            <td>Bank:</td>
            <td>{bank}</td>
          </tr>
          <tr>
            <td>SortCode:</td>
           
            <td><button className="primary-button" onClick={() => copyToClipboard(sortCode)}>
            {sortCode}</button> </td>
          </tr>
          <tr>
            <td>AccountCode:</td>
            <td><button className="primary-button" onClick={() => copyToClipboard(accountCode)}>
            {accountCode}</button> </td>
            
          </tr>
          <tr>
            <td>Bic:</td>
            <td><button className="primary-button" onClick={() => copyToClipboard(bic)}>
            {bic}</button> </td>
           
          </tr>
          <tr>
            <td>Iban:</td>
           
            <td><button className="primary-button" onClick={() => copyToClipboard(iban)}>
            {iban}</button></td>
          </tr>
          <tr>
            <td>Swift:</td>
           
            <td><button className="primary-button" onClick={() => copyToClipboard(swift)}>
            {swift}</button></td>
          </tr>
        </tbody>
      </table>
      <h5>{textCopied}</h5>
    </>
  );
};

export default BankDetails;
